import * as React from "react"

import Seo from "../components/Seo"

const normatividadSecundariaPage = () => (
  <>
    <Seo title="Terminos y Condiciones" />
    <div className="terms">
      <h1 id="normatividad-secundaria">Normatividad Secundaria</h1>
      <p>SECRETARÍA DE EDUCACIÓN PÚBLICA</p>
      <p>SUBSECRETARÍA DE EDUCACIÓN BÁSICA</p>
      <p>DIRECCIÓN GENERAL DE EDUCACIÓN BÁSICA</p>
      <p>DIRECCIÓN DE EDUACIÓN SECUNDARIA</p>
      <h2 id="centro-hidalguense-de-estudios-superiores-secundaria">
        CENTRO HIDALGUENSE DE ESTUDIOS SUPERIORES - SECUNDARIA
      </h2>
      <p>
        El Centro Hidalguense de Estudios Superiores tiene como misión formar
        alumnos altamente capacitados conscientes de su realización personal y
        entorno social; sustentados en los valores humanos, conocimientos,
        cultura y deporte para que sean promotores del desarrollo de su Estado y
        del País.
      </p>
      <p>
        Por lo que el CONSEJO TÉCNICO ESCOLAR, tomando en consideración el
        “MARCO LOCAL DE CONVIVENCIA ESCOLAR DE HIDALGO”, y la “LEY PARA LA
        PREVENCION, ATENCIÓN Y ERRADICACIÓN DE LA VIOLENCIA ESCOLAR EN EL ESTADO
        DE HIDALGO”, emite el siguiente:
      </p>
      <h2 id="acuerdo-de-convivencia">ACUERDO DE CONVIVENCIA</h2>
      <h3 id="derechos-de-los-alumnos">DERECHOS DE LOS ALUMNOS</h3>
      <ol>
        <li>
          <p>
            Recibir una educación con igualdad de oportunidades en un ambiente
            de convivencia inclusiva, democrática y pacífica.
          </p>
        </li>
        <li>
          <p>
            Recibir de manera regular el servicio educativo ofertado por la
            Institución siempre y cuando los padres de familia y/o tutores no
            presenten adeudo de más de una mensualidad de colegiatura.
          </p>
        </li>
        <li>
          <p>
            En caso de presentar alguna discapacidad intelectual, motriz o
            aptitud sobresaliente deberá recibir una educación de acuerdo a sus
            necesidades, siempre y cuando el padre de familia o tutor se
            comprometa a contribuir con ello e informe con oportunidad.
          </p>
        </li>
        <li>
          <p>
            Ser tratado con respeto a su dignidad por todos los miembros de la
            comunidad escolar (autoridades educativas, maestros, personal
            administrativo, de apoyo, padres y compañeros(as)
          </p>
        </li>
        <li>
          <p>
            Conocer el acuerdo de convivencia que regirá el ciclo escolar
            2020-2021.
          </p>
        </li>
        <li>
          <p>
            Conocer los criterios con que será evaluado(a) en cada una de las
            asignaturas y las condiciones para ser promovido al siguiente ciclo
            escolar, así como las opciones de regularización.
          </p>
        </li>
        <li>
          <p>
            Elegir una actividad extracurricular ofertada por la Institución.
          </p>
        </li>
      </ol>
      <h3 id="obligaciones-del-alumno">OBLIGACIONES DEL ALUMNO</h3>
      <p>
        Con el apoyo y compromiso de padres de familia y/o tutores los alumnos
        deberán cumplir con lo siguiente:
      </p>
      <ol>
        <li>
          <p>
            Presentarse puntualmente a clases. El acceso para los alumnos{" "}
            <strong>
              el lunes o el primer día hábil de inicio de semana escolar será
              7:10 am
            </strong>{" "}
            para llevar a cabo ceremonias cívicas. El resto de la semana podrá
            ingresar de 7:15 a 7:25 am. El horario de salida es de 14:50 hrs y
            sólo cuando tenga actividad extracurricular será a las 16:00 hrs. En
            caso de inasistencia deberá reportar a la Dirección de la Escuela
            antes de las 8:00 am.
          </p>
        </li>
        <li>
          <p>
            Mostrar dentro y fuera de la Institución una conducta propia de
            adolescentes educados bajo normas morales establecidas por la
            sociedad.
          </p>
        </li>
        <li>
          <p>
            Asistir con “TODOS” los materiales escolares para cada asignatura o
            actividad asignada.
          </p>
        </li>
        <li>
          <p>
            Asistir regularmente a sus actividades extracurriculares, salvo
            prescripción médica que indique incapacidad o la indicación de un
            médico que determine la baja definitiva para cursar la misma. No hay
            cambios de extracurriculares.
          </p>
        </li>
        <li>
          <p>
            Acudir a todos los eventos académicos, cívicos, culturales y
            deportivos que se le convoquen en los horarios y bajo las
            condiciones emitidas por la Institución.
          </p>
        </li>
        <li>
          <p>
            Presentarse debidamente uniformados cuidando que las condiciones del
            uniforme sean óptimas, evitar hacer modificaciones al uniforme.
          </p>
          <blockquote>
            <p>
              Los varones deberán presentarse con el corte de cabello casquete
              corto regular, sin cortes modernos, sin colorantes, sin peinados
              estrafalarios, sin barba y bigote. Usar el pantalón bien fajado y
              con cinturón de vestir color negro. El zapato para uniforme de
              diario y gala es negro escolar y los tenis totalmente blancos.
            </p>
            <p>
              Las señoritas asistirán a la escuela con las uñas cortas, sin
              esmalte, sin uñas postizas, el cabello largo recogido en una
              coleta, bien peinadas, sin extensiones y sin colorantes. Además,
              evitar el uso de maquillaje. La altura de la falda del uniforme
              escolar es <strong>bajo la rodilla</strong>
            </p>
          </blockquote>
        </li>
        <li>
          <p>
            Portar la credencial escolar y el pase de salida provisto por la
            escuela durante el horario escolar o cualquier actividad organizada
            por la Institución.
          </p>
        </li>
        <li>
          <p>
            Hacer del conocimiento de sus padres o tutores “todos” los
            comunicados emitidos por la escuela.
          </p>
        </li>
        <li>
          <p>Cumplir con TODAS sus tareas y actividades escolares.</p>
        </li>
        <li>
          <p>Dar el máximo esfuerzo en su rendimiento académico</p>
        </li>
        <li>
          <p>
            Respetar a todos los miembros de la comunidad escolar (directivos,
            maestros, personal administrativo, personal de apoyo, compañeros,
            padres de familia y/o tutores) y las pertenencias de cada uno de
            ellos.
          </p>
        </li>
        <li>
          <p>
            Respetar y usar adecuadamente todas las instalaciones, mobiliario y
            servicios que presta la Institución.
          </p>
        </li>
        <li>
          <p>
            Reintegrar en su totalidad los daños y perjuicios realizados en la
            persona o pertenencias de algún miembro de la comunidad escolar.
          </p>
        </li>
        <li>
          <p>Responsabilizarse del cuidado de sus pertenencias.</p>
        </li>
      </ol>
      <h3 id="evitar">EVITAR</h3>
      <ol>
        <li>
          <p>
            Asistir a la escuela con tatuajes, perforaciones, adornos en las
            orejas, lengua, pulseras, collares, adornos excesivos en el cabello
            o cualquier otra modalidad. Sólo se permitirá en las niñas el uso de
            aretes discretos e inofensivos.
          </p>
        </li>
        <li>
          <p>Portar ropa adicional que no corresponda al uniforme.</p>
        </li>
        <li>
          <p>Ingresar en aulas que no le corresponden.</p>
        </li>
        <li>
          <p>Ingerir alimentos en clase y jugar en el aula</p>
        </li>
        <li>
          <p>
            Introducir libros, juguetes, perfumes, desodorantes en aerosol,
            muñecos, mascotas, juegos o realizar actos de hechicería, así como
            portar armas blancas, armas de fuego y/o herramientas con las que
            puedan lastimarse.
          </p>
        </li>
        <li>
          <p>
            Portar y/o hacer uso de teléfonos celulares, aparatos electrónicos,
            de video, entre otros.
          </p>
        </li>
        <li>
          <p>
            Ser objeto de divulgaciones o difusiones ilícitas de información o
            datos personales que permitan identificarlos y atente contra su
            honra, expuestos en medios impresos, digitales, sociales o verbales.
          </p>
        </li>
        <li>
          <p>
            Tomar videos y/o fotografías durante el horario escolar sin
            autorización de la Dirección.
          </p>
        </li>
        <li>
          <p>
            Hacer manifestaciones de inconformidad con gritos, silbidos,
            carteles, agresiones físicas y/o verbales.
          </p>
        </li>
        <li>
          <p>
            Realizar en su persona o incitar a sus compañeros a que realicen
            acciones que atenten contra su integridad física, psicológica y
            dignidad humana.
          </p>
        </li>
        <li>
          <p>Manifestaciones de noviazgo o muestras de afecto excesivas.</p>
        </li>
        <li>
          <p>
            Permanecer en los alrededores de la Institución transcurridos diez
            minutos posteriores al horario de salida Tolerancia hasta las 15:00
            horas)
          </p>
        </li>
      </ol>
      <h3 id="faltas">FALTAS</h3>
      <p>
        Actitudes y comportamientos que alteran la convivencia inclusiva,
        democrática y pacífica de la comunidad educativa y que no involucren
        daño físico, pero sí moral y/o psicológico a otros miembros de esta y
        que entorpecen el desarrollo de las actividades institucionales.
      </p>
      <table>
        <thead>
          <tr>
            <th>FALTAS</th>
            <th>MEDIDAS DISCIPLINARIAS</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <ol>
                <li>
                  <p>Frecuentes inasistencias a clases.</p>
                </li>
                <li>
                  <p>
                    Ausencias constantes en la escuela o comprobarse que se va
                    de “pinta”
                  </p>
                </li>
                <li>
                  <p>Llegar tarde a la escuela</p>
                </li>
                <li>
                  <p>
                    Permanecer en áreas que no les corresponden a sus
                    actividades o en los alrededores del plantel sin
                    autorización posterior al horario de salida (14:50 horas)
                  </p>
                </li>
                <li>
                  <p>
                    No portar la credencial y/o pase de salida institucional.
                  </p>
                </li>
                <li>
                  <p>
                    Asistir sin el uniforme correspondiente a su horario de
                    clases o sin las condiciones establecidas en las
                    obligaciones de los alumnos.
                  </p>
                </li>
                <li>
                  <p>
                    Ausentarse o evitar presentarse a eventos académicos,
                    culturales, cívicos, sociales, deportivos o actividades
                    extracurriculares sin incapacidad médica.
                  </p>
                </li>
                <li>
                  <p>Incumplimiento en tareas y actividades escolares.</p>
                </li>
                <li>
                  <p>
                    Manifestaciones de noviazgo o muestras excesivas de afecto.
                  </p>
                </li>
                <li>
                  <p>Ingresar en aulas que no le sean asignadas.</p>
                </li>
                <li>
                  <p>Ingerir alimentos y/o jugar en el aula.</p>
                </li>
                <li>
                  <p>Realizar juegos de azar.</p>
                </li>
              </ol>
            </td>
            <td>
              <p>
                Se contará con un expediente individual de cada alumno para su
                seguimiento y en caso necesario análisis por parte del Consejo
                Técnico Escolar.
              </p>
              <p>
                Quedando asentado que “TODOS” los miembros de la comunidad
                escolar tienen la responsabilidad de reportar cualquier
                incidencia que atente contra el presente acuerdo de convivencia.
              </p>
              <p>PROTOCOLO A SEGUIR:</p>
              <ol type="A">
                <li>
                  <p>
                    Exhorto al alumno para corregir su conducta y registro en el
                    expediente para seguimiento.
                  </p>
                </li>
                <li>
                  <p>
                    Reunión con padres de familia para notificar sobre el
                    comportamiento de su hijo.
                  </p>
                </li>
                <li>
                  <p>
                    La acumulación de faltas al Acuerdo de Convivencia en el
                    registro individual del alumno será considerado para el
                    concepto con el cual se expida la carta de conducta.
                  </p>
                </li>
                <li>
                  <p>
                    Firma de carta compromiso condicionando la estancia del
                    alumno en la Institución.
                  </p>
                </li>
                <li>
                  <p>
                    En caso de reincidencia el alumno pierde el derecho a carta
                    de buena conducta.
                  </p>
                </li>
              </ol>
            </td>
          </tr>
        </tbody>
      </table>
      <h3 id="faltas-graves">FALTAS GRAVES</h3>
      <p>
        Actitudes y comportamientos que atenten contra la integridad física,
        moral y/o psicológica de cualquier miembro de la comunidad educativa y/o
        al bien común; así como acciones deshonestas que alteren la convivencia
        inclusiva, democrática y pacífica de la comunidad educativa y aquéllas
        tipificadas como hechos posiblemente constitutivos de delito.
      </p>
      <blockquote>
        <p>
          Se contará con un expediente individual de cada alumno para su
          seguimiento y en caso necesario análisis por parte del Consejo Técnico
          Escolar.
        </p>
        <p>
          Quedando asentado que “TODOS” los miembros de la comunidad escolar
          tienen la responsabilidad de reportar cualquier incidencia que atente
          contra el presente acuerdo de convivencia.
        </p>
      </blockquote>
      <p>PROTOCOLO A SEGUIR:</p>
      <ul>
        <li>
          Registro en el expediente del alumno y reunión con padres de familia
          para notificar del comportamiento de su hijo, asignándose alguna(s) de
          las siguientes acciones dependiendo la gravedad de la falta:
        </li>
      </ul>
      <table>
        <thead>
          <tr>
            <th>FALTAS</th>
            <th>MEDIDAS DISCIPLINARIAS</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <ul>
                <li>
                  La reiterada falta de respeto al ejercicio del derecho al
                  estudio de sus compañeros.
                </li>
              </ul>
            </td>
            <td>
              <ul>
                <li>
                  <p>Brindar apoyo académico a compañeros de menor grado.</p>
                </li>
                <li>
                  <p>
                    Preparar una exposición, conferencia, etc. que explique las
                    consecuencias de sus actos.
                  </p>
                </li>
                <li>
                  <p>
                    Elaborar y publicar carteles, trípticos, folletos, entre
                    otros, que fomenten la convivencia inclusiva, democrática y
                    pacífica.
                  </p>
                </li>
                <li>
                  <p>
                    Apoyar en biblioteca, talleres o laboratorios priorizando
                    siempre su integridad física.
                  </p>
                </li>
                <li>
                  <p>
                    Servicio comunitario que beneficie a la comunidad escolar a
                    la que pertenece.
                  </p>
                </li>
              </ul>
              <ul>
                <li>
                  <p>
                    El padre de familia y/o tutor deberá acompañar al estudiante
                    durante su jornada escolar hasta modificar su
                    comportamiento.
                  </p>
                </li>
                <li>
                  <p>
                    En caso de no modificar su actitud el alumno pierde el
                    derecho a carta de buena conducta.
                  </p>
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              <ul>
                <li>
                  Hacer manifestaciones de inconformidad con gritos, silbidos,
                  carteles agresiones físicas y/o verbales.
                </li>
              </ul>
            </td>
            <td>
              <ul>
                <li>
                  <p>
                    Firma de carta compromiso condicionando la estancia del
                    alumno en la Institución.
                  </p>
                </li>
                <li>
                  <p>
                    En caso de no modificar su comportamiento el alumno pierde
                    el derecho a carta de buena conducta.
                  </p>
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              <ul>
                <li>
                  <p>
                    Desafiar a los directivos, a los docentes, así como al resto
                    del personal en el ejercicio de sus funciones, cuando vayan
                    acompañados de actitudes, o expresiones insultantes,
                    despectivas, desafiantes o amenazadoras.
                  </p>
                </li>
                <li>
                  <p>
                    Agredir por cualquier medio a algún miembro de la comunidad
                    escolar.
                  </p>
                </li>
                <li>
                  <p>
                    Insultar o amenazar a cualquier miembro de la comunidad
                    escolar.
                  </p>
                </li>
                <li>
                  <p>
                    Discriminación contra cualquier miembro de la comunidad
                    escolar.
                  </p>
                </li>
                <li>
                  <p>
                    Portar cualquier tipo de arma u objeto que pueda causar daño
                  </p>
                </li>
                <li>
                  <p>
                    Consumir y/o distribuir cualquier tipo de sustancia tóxica
                    en la Institución o eventos organizados por la misma.
                  </p>
                </li>
                <li>
                  <p>
                    Grabación, publicidad o difusión no autorizada de imágenes
                    de los miembros de la comunidad escolar, cuando resulte
                    contrario a su derecho a la intimidad o no sea una evidencia
                    académica.
                  </p>
                </li>
                <li>
                  <p>
                    Usar, portar, distribuir o exhibir material de tipo
                    pornográfico, así como acceder a sitios de este tipo en
                    internet.
                  </p>
                </li>
              </ul>
              <ul>
                <li>
                  <p>
                    Incurrir de manera individual o colectiva en actos de
                    vandalismo u ocasionar daños a la infraestructura escolar.
                  </p>
                </li>
                <li>
                  <p>Lesionar por juegos bruscos a un compañero.</p>
                </li>
                <li>
                  <p>Ser partícipe de robo</p>
                </li>
                <li>
                  <p>
                    Deterioro del edificio, material didáctico, mobiliario
                    escolar o pertenencias de profesores, personal de apoyo y/o
                    compañeros.
                  </p>
                </li>
                <li>
                  <p>
                    Participar en peleas, riñas, altercados y/o incurrir en
                    conductas físicamente agresivas.
                  </p>
                </li>
              </ul>
            </td>
            <td>
              <ul>
                <li>
                  <p>
                    Reparar el daño económico y/o material a plena satisfacción
                    del afectado.
                  </p>
                </li>
                <li>
                  <p>
                    Firma de carta compromiso condicionando la estancia del
                    alumno en la Institución.
                  </p>
                </li>
                <li>
                  <p>
                    El padre de familia y/o tutor deberá acompañar al estudiante
                    durante su jornada escolar hasta modificar su
                    comportamiento.
                  </p>
                </li>
                <li>
                  <p>
                    Canalización a servicios de atención profesional para
                    mejorar su comportamiento. En caso que el padre de familia
                    y/o tutor no brinde la atención requerida para el alumno la
                    escuela se reserva el derecho de seguir brindando el
                    servicio educativo.
                  </p>
                </li>
                <li>
                  <p>
                    Reportar al REPAEVE (Registro Estatal para la Prevención,
                    Atención y Erradicación de Violencia Escolar) cuando se
                    genere cualquier tipo de violencia escolar.
                  </p>
                </li>
                <li>
                  <p>
                    En caso de no modificar su comportamiento el alumno pierde
                    el derecho a carta de buena conducta y será transferido a
                    otra Institución Educativa.
                  </p>
                </li>
              </ul>
            </td>
          </tr>
        </tbody>
      </table>
      <h3 id="compromisos-del-acuerdo-de-convivencia">
        COMPROMISOS DEL ACUERDO DE CONVIVENCIA
      </h3>
      <h4 id="alumno-a-">ALUMNO(A)</h4>
      <p>Me comprometo a:</p>
      <ul>
        <li>
          Cumplir con todas mis obligaciones establecidas en el presente Acuerdo
          de Convivencia Escolar teniendo una conducta libre de violencia y
          actuando de manera responsable con cada una de las normas
          institucionales.
        </li>
      </ul>
      <h4 id="padre-de-familia-y-o-tutor">PADRE DE FAMILIA Y/O TUTOR</h4>
      <p>Me comprometo a:</p>
      <ul>
        <li>
          <p>
            Cumplir con todas mis obligaciones como padre y/o madre y/o tutor
            que establece la ley.
          </p>
        </li>
        <li>
          <p>
            Respetar, cumplir y hacer que mi hijo(a) cumpla con el presente
            acuerdo de convivencia escolar.
          </p>
        </li>
        <li>
          Asistir a todas las reuniones que me convoque el personal directivo,
          docente y de apoyo para tratar asuntos relacionados con la formación
          integral de mi hijo(a), respetando los horarios establecidos.
        </li>
        <li>
          Reparar los daños físicos y/o económicos que corresponda a mi hijo (a)
          si provoca algún daño físico o en sus pertenencias a cualquier miembro
          de la comunidad educativa o a la infraestructura de la Institución.
        </li>
        <li>
          Abstenerme de llevar a mi hijo(a) materiales durante el horario
          escolar. El alumno debe responsabilizarse de llegar a la escuela con
          TODO lo necesario para sus actividades.
        </li>
        <li>
          Dar seguimiento a los tratamientos médicos o psicológicos que mi
          hijo(a) requiera.
        </li>
        <li>
          Informar oportunamente a la Institución en caso de que mi hijo(a)
          presente alguna discapacidad para el aprendizaje, aptitud
          sobresaliente o discapacidad motriz para que reciba una educación de
          acuerdo a sus necesidades.
        </li>
        <li>
          Vigilar que mi hijo(a) evite permanecer en los alrededores de la
          Institución después del horario establecido para la salida (14:50
          horas)
        </li>
        <li>
          Evitar que mi hijo(a) consuma, porte y/o distribuya cualquier
          sustancia tóxica
        </li>
        <li>
          Vigilar que mi hijo(a) prescinda portar objetos de valor, cantidades
          excesivas de dinero, juguetes, mascotas, celulares o cualquier otro
          tipo de medio electrónico portátil. LA ESCUELA QUEDA DESLINDADA POR LA
          PÉRDIDA DE CUALQUIER PERTENENCIA DE MI HIJO(A).
        </li>
        <li>
          Apoyar y supervisar el cumplimiento de las actividades escolares de mi
          hijo(a)
        </li>
        <li>
          Entregar el comprobante de incapacidad médica que justifique las
          ausencias de mi hijo(a).
        </li>
        <li>
          Tratar cualquier asunto que tenga que ver con el desempeño académico o
          conductual de mi hijo(a) en la Dirección de la Escuela. Evitando
          involucrar a terceras personas.
        </li>
        <li>
          Autorizar que se haga uso de la imagen de mi hijo(a) para
          publicaciones en distintos medios cuando se trate de evidencias
          académicas, culturales, cívicas y/o deportivas.
        </li>
        <li>
          Asistir puntualmente a recoger a mi hijo (a) en los horarios
          establecidos por la escuela; después del horario de salida la
          Institución depositará a los menores en las instancias legales
          correspondientes.
        </li>
        <li>
          Horario de salida de materias académicas: 14:50 hrs; horario de salida
          de actividades extracurriculares a las 16:00 hrs.
        </li>
      </ul>
      <h4 id="instituci-n-educativa">INSTITUCIÓN EDUCATIVA</h4>
      <p>Me comprometo a:</p>
      <ul>
        <li>
          <p>
            Respetar los derechos de los estudiantes establecidos en el presente
            Acuerdo de Convivencia Escolar y hacer que se respeten.
          </p>
        </li>
        <li>
          <p>
            Aplicar las medidas disciplinarias con justicia, imparcialidad y
            transparencia.
          </p>
        </li>
        <li>
          <p>
            Que cualquier asunto no previsto en el presente Acuerdo de
            Convivencia sea tratado por los integrantes del Consejo Técnico
            Escolar para determinar las medidas a implementar.
          </p>
        </li>
      </ul>
      <div>
        <p>
          <strong>Mtra. Claudia Paz Reséndiz Hernández</strong>
        </p>
        <p>
          <strong>DIRECTORA</strong>
        </p>
      </div>
    </div>
  </>
)

export default normatividadSecundariaPage
